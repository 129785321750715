<template>
  <div id="export_layer" ref="export_layer" style="display: none">
    <div class="explore_inner_layer map_image">
      <div class="row">
        <div class="col-md-12">
          <div class="A4">
            <div class="card">
              <div class="card-body">
                <div class="map_left b-2p">
                  <div class="mapimg viewmapimg exportmap text-center">
                    <img
                      v-if="mapImgSource"
                      :src="mapImgSource"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="my_legend">
                  <div
                    class="legendbox border p-3 d-none d-md-block"
                    v-if="selection.layers && selection.layers.length > 0"
                  >
                    <div class="my-legend">
                      <div class="mainhead d-flex align-items-center">
                        <h5 class="fw-bold">Legends</h5>
                      </div>
                      <div class="row">
                        <div
                          class="legend-scale col-4"
                          v-for="(layer, index) in selection.layers"
                          :key="layer.id"
                        >
                          <div class="card">
                            <div class="card-body">
                              <template
                                v-if="layer.File_Type.Filetype === 'Raster'"
                              >
                                <div class="legend_layer_title">
                                  {{ layer.Layer_Name }}
                                </div>
                                <ul class="legend-labels ms-2">
                                  <template
                                    v-if="layer.range && layer.range.length > 1"
                                  >
                                    <li>
                                      <span
                                        :style="{ background: rasterColor[0] }"
                                      ></span>
                                      {{ layer.range[0] }} -
                                      {{ layer.range[1] }}
                                    </li>
                                    <li>
                                      <span
                                        :style="{ background: rasterColor[1] }"
                                      ></span>
                                      {{ layer.range[2] }} -
                                      {{ layer.range[3] }}
                                    </li>
                                    <li>
                                      <span
                                        :style="{ background: rasterColor[2] }"
                                      ></span>
                                      {{ layer.range[4] }} -
                                      {{ layer.range[5] }}
                                    </li>
                                    <li>
                                      <span
                                        :style="{ background: rasterColor[3] }"
                                      ></span>
                                      {{ layer.range[6] }} -
                                      {{ layer.range[7] }}
                                    </li>
                                    <li>
                                      <span
                                        :style="{ background: rasterColor[4] }"
                                      ></span>
                                      {{ layer.range[8] }} -
                                      {{ layer.range[9] }}
                                    </li>
                                  </template>
                                </ul>
                                <hr class="my-3" />
                              </template>
                              <template v-else>
                                <template
                                  v-if="layer.shapeType === 'LineString'"
                                >
                                  <div class="legend_layer_title">
                                    {{ layer.Layer_Name }}
                                  </div>
                                  <ul class="legend-labels ms-2">
                                    <li>
                                      <span
                                        style="background-color: blue"
                                      ></span>
                                      {{ layer.Layer_Name }}
                                    </li>
                                  </ul>
                                  <hr class="my-3" />
                                </template>
                                <template
                                  v-if="
                                    selection.attributes[index] &&
                                    selection.attributes[index].length > 0 &&
                                    layer.shapeType !== 'LineString'
                                  "
                                >
                                  <template>
                                    <div class="legend_layer_title">
                                      {{ layer.Layer_Name }}
                                    </div>
                                    <template
                                      v-if="
                                        layer.shapeType === 'Point' &&
                                        layer.range.length > 0
                                      "
                                    >
                                      <span
                                        class="ms-2 text-capitalize legend_selected_attribute"
                                        >{{
                                          layer.selectedAttributes.replaceAll(
                                            "_",
                                            " "
                                          )
                                        }}</span
                                      >
                                      <ul class="legend-labels ms-2">
                                        <template v-if="layer.range.length > 1">
                                          <li v-for="i in 5" :key="i">
                                            <div
                                              class="d-flex justify-content-center"
                                              style="
                                                width: 20px;
                                                margin-right: 10px;
                                              "
                                            >
                                              <div
                                                class="point"
                                                :class="'point-' + i"
                                                :style="{
                                                  background: 'darkgreen',
                                                }"
                                              ></div>
                                            </div>
                                            {{ layer.range[i - 1] }} -
                                            {{ layer.range[i] }}
                                          </li>
                                        </template>
                                        <li v-else>
                                          <div
                                            class="d-flex justify-content-center"
                                            style="
                                              width: 20px;
                                              margin-right: 10px;
                                            "
                                          >
                                            <div class="point point-3"></div>
                                          </div>
                                          {{ layer.range[0] }}
                                        </li>
                                      </ul>
                                    </template>
                                    <template
                                      v-else-if="
                                        (layer.shapeType === 'MultiPolygon' ||
                                          layer.shapeType === 'Polygon') &&
                                        layer.range.length > 0
                                      "
                                    >
                                      <div
                                        class="ms-2 text-capitalize legend_selected_attribute"
                                      >
                                        {{
                                          layer.selectedAttributes.replaceAll(
                                            "_",
                                            " "
                                          )
                                        }}
                                      </div>
                                      <ul class="legend-labels ms-2">
                                        <template v-if="layer.range.length > 1">
                                          <li v-for="i in 5" :key="i">
                                            <span
                                              :style="{
                                                background: colors[i - 1],
                                              }"
                                            ></span>
                                            {{ layer.range[i - 1] }} -
                                            {{ layer.range[i] }}
                                          </li>
                                        </template>
                                        <li v-else>
                                          <div
                                            class="d-flex justify-content-center"
                                            style="
                                              width: 20px;
                                              margin-right: 10px;
                                            "
                                          >
                                            <span></span>
                                          </div>
                                          {{ layer.range[0] }}
                                        </li>
                                      </ul>
                                    </template>
                                  </template>
                                  <hr class="my-3" />
                                </template>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["mapImgSource", "rasterColor", "colors", "selection"],
};
</script>

<style scoped>
.point {
  border-radius: 50%;
  background-color: red;
}

.point-1 {
  height: 10px;
  width: 10px;
}

.point-2 {
  height: 12px;
  width: 12px;
}

.point-3 {
  height: 14px;
  width: 14px;
}

.point-4 {
  height: 16px;
  width: 16px;
}

.point-5 {
  height: 18px;
  width: 18px;
}
.legend-scale {
  min-width: max-content;
}
</style>
