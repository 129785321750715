<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)" v-if="category">
      <div class="row">
        <div class="col-12 mb-3">
          <validation-provider
            v-slot="{ errors, pristine }"
            name="category"
            rules="required"
          >
            <label for="category" class="form-label">Name</label>
            <input
              type="text"
              class="form-control"
              :class="{
                'is-invalid': errors[0],
                'is-valid': !errors[0] && !pristine,
              }"
              id="category"
              v-model="form.Category"
              placeholder="Enter Name"
            />
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>
        <div class="col-12 mb-3">
          <label for="description" class="form-label">Description</label>
          <textarea
            type="text"
            class="form-control"
            id="description"
            v-model="form.description"
          ></textarea>
        </div>
      </div>
      <button
        v-if="!saving"
        type="submit"
        class="btn btn-primary btn-sm px-2 me-3"
      >
        Update
      </button>
      <button
        v-else
        class="btn btn-primary btn-sm px-2 me-3"
        type="button"
        disabled
      >
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Loading...
      </button>
      <button
        type="button"
        class="btn btn-danger btn-sm px-2"
        @click.prevent="cancelUpdate"
      >
        Cancel
      </button>
    </form>
  </validation-observer>
</template>

<script>
export default {
  name: "CategoryEditForm",
  props: {
    category: {
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      loading: true,
      form: {
        Category: "",
        description: "",
      },
    };
  },
  watch: {
    category() {
      this.setData();
    },
  },
  mounted() {
    this.loading = false;
    this.setData();
  },
  methods: {
    cancelUpdate() {
      this.$emit("cancel");
    },
    setData() {
      this.loading = true;
      this.form = this.category;
      this.loading = false;
    },
    resetForm() {
      this.form = this.category;
      this.$refs.observer.reset();
    },
    submit() {
      this.saving = true;
      let data = new FormData();
      data.append("Category", this.form.Category);
      data.append("description", this.form.description);

      this.$repository.category
        .edit(data, this.category.id)
        .then(() => {
          this.$toast.success("Category Updated");
          this.resetForm();
          this.$emit("updated");
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$toast.warning(
              "You are not authorized. Please login and try again."
            );
          }
        })
        .finally(() => {
          this.saving = false;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
